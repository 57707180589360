import { createSlice } from '@reduxjs/toolkit';

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    isLoaded: false,
    contacts: {
      sfa: {
        email: '',
        fax: '',
        tel: '',
        website: '',
      },
      site: {
        email: '',
        fax: '',
        tel: '',
      },
    },
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      youtube: '',
    },
    settings: {
      recaptcha_key: '',
    },
    error: '',
  },
  reducers: {
    /**
     * Save the contacts
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The contacts Objects
     * @param {Object} action.payload.contacts  The contacts
     * @param {Object} action.payload.settings  The contacts settings
     * @param {Object} action.payload.social  The social contacts
     */
    saveContacts: (state, action) => {
      state.isLoaded = true;
      state.contacts = action.payload.contacts;
      state.settings = action.payload.settings;
      state.social = action.payload.social;
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { saveContacts, saveError } = contactsSlice.actions;

export default contactsSlice.reducer;
