import PropTypes from 'prop-types';

const IconQuestionMark = ({ className, fill }) => (
  <div className={`svg ico-questionmark ${className}`}>
    <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.285 11.4917C6.18333 9.87 7.91 8.91333 8.91333 7.47833C9.975 5.97333 9.38 3.16167 6.37 3.16167C4.39833 3.16167 3.43 4.655 3.02167 5.89167L0 4.62C0.828333 2.135 3.08 0 6.35833 0C9.1 0 10.9783 1.24833 11.935 2.81167C12.7517 4.15333 13.23 6.66167 11.97 8.52833C10.57 10.5933 9.22833 11.2233 8.505 12.5533C8.21333 13.09 8.09667 13.44 8.09667 15.1667H4.725C4.71333 14.2567 4.57333 12.775 5.285 11.4917ZM8.70333 19.8333C8.70333 21.1167 7.65333 22.1667 6.37 22.1667C5.08667 22.1667 4.03667 21.1167 4.03667 19.8333C4.03667 18.55 5.08667 17.5 6.37 17.5C7.65333 17.5 8.70333 18.55 8.70333 19.8333Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconQuestionMark.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconQuestionMark.defaultProps = {
  className: '',
  fill: '#0D1C55',
};

export default IconQuestionMark;
