import PropTypes from 'prop-types';

const IconBack = ({ className, fill }) => (
  <div className={`svg ico-back ${className}`}>
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6419 0.820886L14.612 2.79103L6.15027 11.2528H25.0439V14.0307H6.15027L14.612 22.4924L12.6419 24.4626L0.821037 12.6417L12.6419 0.820886Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconBack.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconBack.defaultProps = {
  className: '',
  fill: '#ffffff',
};

export default IconBack;
