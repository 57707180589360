import PropTypes from 'prop-types';

const IconShare = ({ className, bgColor, contentColor }) => (
  <div className={`svg ico-share ${className}`}>
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 0C22.1913 0.0499926 26.6701 1.92167 29.9517 5.20342C33.2333 8.48516 35.0488 12.9083 34.999 17.5C35.0488 22.0917 33.2333 26.5148 29.9517 29.7966C26.6701 33.0783 22.1913 34.95 17.5 35C12.8087 34.95 8.32985 33.0783 5.04829 29.7966C1.76673 26.5148 -0.0487873 22.0917 0.000997515 17.5C-0.0487873 12.9083 1.76673 8.48516 5.04829 5.20342C8.32985 1.92167 12.8087 0.0499926 17.5 0Z"
        fill={bgColor}
      />
      <path
        d="M20.8459 20.6588C20.319 20.6588 19.8282 20.812 19.4143 21.0754L15.2375 18.1593C15.3045 17.9252 15.3412 17.6783 15.3412 17.4227C15.3412 17.1671 15.3045 16.9202 15.2375 16.6861L19.3015 13.8488C19.7376 14.1585 20.2702 14.3412 20.8458 14.3412C22.3208 14.3412 23.5164 13.1455 23.5164 11.6706C23.5164 10.1957 22.3208 9 20.8459 9C19.371 9 18.1753 10.1957 18.1753 11.6706C18.1753 11.8654 18.1967 12.0551 18.2363 12.238L14.069 15.1475C13.6623 14.8969 13.1834 14.7521 12.6706 14.7521C11.1957 14.7521 10 15.9478 10 17.4227C10 18.8976 11.1956 20.0933 12.6706 20.0933C13.1834 20.0933 13.6623 19.9485 14.069 19.6979L18.2685 22.6299C18.2081 22.8529 18.1753 23.0873 18.1753 23.3294C18.1753 24.8043 19.371 26 20.8459 26C22.3208 26 23.5165 24.8043 23.5165 23.3294C23.5165 21.8545 22.3208 20.6588 20.8459 20.6588Z"
        fill={contentColor}
      />
    </svg>
  </div>
);

IconShare.propTypes = {
  className: PropTypes.string,
  bgColor: PropTypes.string,
  contentColor: PropTypes.string,
};

IconShare.defaultProps = {
  className: '',
  bgColor: '#ffffff',
  contentColor: '#0D1C55',
};
export default IconShare;
