import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

/** Components */
import PictoUnipop from '../images/PictoUnipop';

const Loader = () => {
  const [isLoaderHidden, setIsLoaderHidden] = useState(false);
  const isLoaded = useSelector((state) => state.app.isContentLoaded);

  useEffect(() => {
    setIsLoaderHidden(false);
    if (isLoaded) {
      /** Add a delay to match the css transition in _loader.scss */
      setTimeout(() => {
        setIsLoaderHidden(true);
      }, 600);
    }
  }, [isLoaded, setIsLoaderHidden]);

  return (
    <>
      {!isLoaderHidden && (
        <div className={`loader ${isLoaded ? 'is-loaded' : ''}`}>
          <PictoUnipop />
        </div>
      )}
    </>
  );
};

export default Loader;
