import PropTypes from 'prop-types';
import ContactBotContainer from './ContactBotContainer';

const Template = ({ template, className, layout, children }) => {
  process.env.NODE_ENV === 'development' && console.info(`<Template /> - ${template.name}`);

  let theme = layout ? `l-${layout}` : '';
  if (template.id !== 'front-page') {
    theme = 'l-light';
  }

  return (
    <main id={template.id} className={`page template template-${template.id} ${className} ${theme}`}>
      {children}
      <ContactBotContainer />
    </main>
  );
};

Template.propTypes = {
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  layout: PropTypes.oneOf(['dark', 'light']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Template.defaultProps = {
  className: '',
};

export default Template;
