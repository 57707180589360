import { useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** Redux */
import { saveList as savePagesList, saveError as savePagesError } from './redux/pagesSlice';
import { saveList as saveNewsList, saveError as saveNewsError } from './redux/newsSlice';
import { fetchFeedsBegin, fetchFeedsSuccess, fetchFeedsFailure } from './redux/feedsSlice';
import { saveList as saveFeaturedList, saveError as saveFeaturedError } from './redux/featuredCoursesSlice';
import { saveContacts, saveError as saveContactsError } from './redux/contactsSlice';

/** Hooks */
import { useFetchTrainings } from './hooks/useFetchTrainings';

/** Utils */
import './util/CustomResize';
import { responseStatus } from './util/Fetch';

/** Components */
import PagesRouter from './components/PagesRouter';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Loader from './components/Loader';
import SEO from './components/SEO';

/** Styles */
import './fonts/Gotham.css';
import './fonts/GothamNarrow.css';
import './fonts/icomoon.css';
import './App.scss';

const App = () => {
  process.env.NODE_ENV === 'development' && console.info('<App />');

  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.pages.currentPage);
  const isPagesListLoaded = useSelector((state) => state.pages.isLoaded);
  const isNewsListLoaded = useSelector((state) => state.news.isLoaded);
  const isContactsLoaded = useSelector((state) => state.contacts.isLoaded);

  /**
   * Verify autodetected language and update it if necessary
   */
  useEffect(() => {
    const i18nLanguage = i18n.language.substring(0, 2);
    if (!i18n.languages.includes(i18nLanguage)) {
      /** Set the language to 'fr' if the detected language is not supported */
      i18n.changeLanguage('fr');
    } else if (i18n.language.length > 2) {
      /** Format the detected language to a two-letter language code */
      i18n.changeLanguage(i18nLanguage);
    }
    /** Redirect the page to the language folder */
    if (i18n.language !== 'fr' && window.location.pathname === '/') {
      window.location.replace(`${window.location.href + i18n.language}/`);
    }
  }, [i18n]);

  const logFetchError = (url, error) => {
    console.error(`Error fetching ${url}:`, error);
  };

  /**
   * Fetch the pages list and save it to Redux
   */
  useEffect(() => {
    const url = `${process.env.REACT_APP_REST_URL}pages?${new URLSearchParams({
      orderby: 'menu_order',
      order: 'asc',
      per_page: 100,
    }).toString()}`;

    fetch(url, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(savePagesList(response)))
      .catch((error) => {
        logFetchError(url, error);
        dispatch(savePagesError(error.message));
      });
  }, [dispatch]);

  /**
   * Fetch the news list and save it to Redux
   */
  useEffect(() => {
    const url = `${process.env.REACT_APP_REST_URL}news?${new URLSearchParams({
      orderby: 'date',
      order: 'desc',
      per_page: 100,
    }).toString()}`;

    fetch(url, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(saveNewsList(response)))
      .catch((error) => {
        logFetchError(url, error);
        dispatch(saveNewsError(error.message));
      });
  }, [dispatch]);

  /**
   * Fetch the facebook feeds list and save it to Redux
   */
  useEffect(() => {
    const storedFeeds = localStorage.getItem('feeds');
    const storedTimestamp = localStorage.getItem('feedsTimestamp');
    const url = `${process.env.REACT_APP_REST_URL}fb`;

    if (!storedFeeds || !storedTimestamp || hasFeedExpired(storedTimestamp)) {
      dispatch(fetchFeedsBegin());
      fetch(url, {
        method: 'GET',
        headers: { Accept: 'application/json' },
      })
        .then(responseStatus)
        .then((response) => response.json())
        .then((json) => dispatch(fetchFeedsSuccess(json)))
        .catch((error) => {
          logFetchError(url, error);
          dispatch(fetchFeedsFailure(error.toString()));
        });
    } else {
      dispatch(fetchFeedsSuccess(JSON.parse(storedFeeds)));
    }
  }, [dispatch]);

  /**
   * Check if the feed has expired
   * @param {number} timestamp - The timestamp of the feed
   */
  const hasFeedExpired = (timestamp) => {
    const currentTime = new Date().getTime();
    const feedAge = (currentTime - timestamp) / (1000 * 60 * 30);
    return feedAge > 1;
  };

  /**
   * Fetch the featured courses list and save it to Redux
   */
  useEffect(() => {
    const url = `${process.env.REACT_APP_REST_URL}featured_courses?${new URLSearchParams({
      'filter[orderby]': 'meta_value_num',
      'filter[meta_key]': 'start_date',
      'filter[order]': 'asc',
      per_page: 100,
    }).toString()}`;

    fetch(url, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(saveFeaturedList(response)))
      .catch((error) => {
        logFetchError(url, error);
        dispatch(saveFeaturedError(error.message));
      });
  }, [dispatch]);

  /**
   * Fetch the contacts option page and save it to redux
   */
  useEffect(() => {
    const url = `${process.env.REACT_APP_REST_URL}contacts`;

    fetch(url, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(saveContacts(response)))
      .catch((error) => {
        logFetchError(url, error);
        dispatch(saveContactsError(error.message));
      });
  }, [dispatch]);

  /**
   * Fetch the trainings list and save it to Redux
   */
  const fetchTrainings = useFetchTrainings();
  useEffect(() => {
    fetchTrainings();
  }, [fetchTrainings]);

  /**
   * Add development tools
   */
  const DevTools = process.env.NODE_ENV === 'development' ? lazy(() => import('./Dev')) : <></>;

  /**
   * Get the theme mode
   */
  const theme = useSelector((state) => {
    let themeMode = state.theme.mode;
    if (currentPage && currentPage.template && currentPage.template.id !== 'front-page') {
      themeMode = 'light';
    }
    return themeMode;
  });
  return (
    <>
      <SEO
        title="Université Populaire Luxembourg"
        description="Université Populaire Luxembourg"
        url={window.location.href}
        lang={i18n.language}
        image={`${process.env.REACT_APP_PUBLIC_URL}/social.png`}
      />
      <Loader />
      <BrowserRouter>
        {isPagesListLoaded && isNewsListLoaded && isContactsLoaded && (
          <div id="main" className={`l-${theme}`} data-theme={theme}>
            <Header />
            <PagesRouter />
            <Footer />
          </div>
        )}
      </BrowserRouter>
      <Suspense fallback={<Loader />}>{process.env.NODE_ENV === 'development' && <DevTools />}</Suspense>
    </>
  );
};

export default App;
