import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    currentPage: { template: {} },
  },
  reducers: {
    /**
     * Save the pages list
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The pages list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload;
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current page
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The current page
     */
    saveCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { saveList, saveError, saveCurrentPage } = pagesSlice.actions;

/**
 * Select the front page
 *
 * @return {Object}
 */
export const selectFrontPage = createSelector(
  (state) => state.pages,
  (pages) => pages.list.filter((page) => page.template.id === 'front-page' && page.lang === 'fr')[0]
);

/**
 * Select a page
 *
 * @param {Object} state  The React state
 * @param {string} template  The page template id
 * @param {string} language  The page language
 * @return {Object}
 */
export const selectPage = createSelector(
  (state) => state.pages,
  (_, template) => template,
  (_, __, language) => language,
  (pages, template, language) => pages.list.filter((page) => page.template.id === template && page.lang === language)[0]
);

/**
 * Get the training pages
 *
 * @param {Object} pages  The pages list
 * @param {string} language  The pages language
 * @return {object[]}
 */
const getTrainingsPages = (pages, language) =>
  pages.list
    .filter(
      (page) => page.template.id.startsWith('trainings') && page.template.id !== 'trainings' && page.lang === language
    )
    .sort((a, b) => a.menu_order > b.menu_order);

/**
 * Select the training pages
 *
 * @param {Object} state  The React state
 * @param {string} language  The pages language
 * @return {object[]}
 */
export const selectTrainingPages = createSelector(
  (state) => state.pages,
  (_, language) => language,
  (pages, language) => getTrainingsPages(pages, language)
);

/**
 * Select the domain names
 *
 * @param {Object} state  The React state
 * @param {string} language  The pages language
 * @return {object[]}  The domain names, keyed by `categorieCodeUnipop` key
 */
export const selectDomainNames = createSelector(
  (state) => state.pages,
  (_, language) => language,
  (pages, language) => {
    const trainingsPages = getTrainingsPages(pages, language);
    const domainsNames = {};
    trainingsPages.forEach((page) => {
      domainsNames[page.template.id.replace('trainings-', '').toUpperCase()] = page.title;
    });
    return domainsNames;
  }
);

export default pagesSlice.reducer;
