import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconMoon from '../../images/icon-moon';
import IconSun from '../../images/icon-sun';
import { toggleTheme } from '../../redux/themeSlice';

const ThemeSwitcher = () => {
  const theme = useSelector((state) => state.theme.mode);
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleTheme());
  };

  return (
    <div role="presentation" className="theme-toggle" onClick={handleToggle} data-theme={theme}>
      <span className="icon sun">
        <IconSun />
      </span>
      <span className="icon moon">
        <IconMoon />
      </span>
      <span className="knob"></span>
    </div>
  );
};

export default ThemeSwitcher;
