import PropTypes from 'prop-types';

const Icon = ({ icon, layout, ariaHidden, ariaLabel }) => {
  const ariaProps = {
    'aria-hidden': (ariaHidden && !ariaLabel) || null,
    'aria-label': ariaLabel,
  };
  return <span {...ariaProps} className={`icon icon-${icon} ${layout ? `l-${layout}` : ''}`}></span>;
};

Icon.propTypes = {
  icon: PropTypes.oneOf([
    'linkedin',
    'l',
    'facebook',
    'f',
    'instagram',
    'i',
    'twitter',
    't',
    'youtube',
    'y',
    'globe',
    'email',
    'clock',
    'marker',
    'arrow-left',
    'arrow-right',
    'triangle-up',
    'cross',
    'chevron-down',
    'search',
    'times',
    'bars',
    'plus',
    'phone',
  ]).isRequired,
  layout: PropTypes.oneOf(['round', 'square-outline']),
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Icon.defaultProps = {
  ariaHidden: true,
};

export default Icon;
