import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMenuTriggerState } from '@react-stately/menu';
import { useMenuTrigger } from '@react-aria/menu';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';

/** Redux */
import { selectPage } from '../../../redux/pagesSlice';

/** Hooks */
import useNavigateWithLoader from '../../../hooks/useNavigateWithLoader';
import useCloseWithTransition from '../../../hooks/useCloseWithTransition';

/** Components */
import MainMenu from '../../menus/MainMenu';
import SocialMenu from '../../menus/SocialMenu';
import Icon from '../../Icon';
import Button from '../../Button';
import Link from '../../Link';
import FormNewsletter from './FormNewsletter';

const NavPrimary = () => {
  const { t: __ } = useTranslation();

  const buttonRef = useRef();
  const menuState = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, menuState, buttonRef);
  const { buttonProps } = useButton(menuTriggerProps, buttonRef);

  return (
    <nav className="nav header-nav header-nav-primary">
      <button {...buttonProps} ref={buttonRef} className="button menu-button">
        <span className="button-label">{__('menu.Menu')}</span>
        <Icon icon="bars" />
      </button>
      {menuState.isOpen && (
        <Overlay props={menuProps} autoFocus={menuState.focusStrategy} onClose={() => menuState.close()} />
      )}
    </nav>
  );
};

const Overlay = (props) => {
  const { navigate } = useNavigateWithLoader();
  const { t: __, i18n } = useTranslation();

  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 1100);
  const { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: false,
      isOpen: true,
      isDismissable: false,
    },
    overlayRef
  );

  const pageContact = useSelector((state) => selectPage(state, 'contact', i18n.language));

  /**
   * Navigate to the selected page
   *
   * @param {string} link  The link to navigate to
   */
  const navigateToPage = (link) => {
    closeWithTransition();
    navigate(link);
  };

  return (
    <FocusScope restoreFocus>
      <div ref={overlayRef} {...overlayProps} className="nav-overlay">
        <DismissButton onDismiss={props.onClose} />
        <div className="nav-overlay-primary">
          <MainMenu onAction={navigateToPage} />
        </div>
        <ul className="nav-overlay-secondary">
          <li className="menu-contact">
            <Link url={pageContact.link} onPress={() => navigateToPage(pageContact.link)}>
              <Icon icon="email" layout="round" /> {__('menu.Contact')}
            </Link>
          </li>
          <li>
            <FormNewsletter />
          </li>
          <li>
            <SocialMenu />
          </li>
        </ul>
        <Button className="close-button" icon="times" onPress={closeWithTransition} ariaLabel={__('buttons.Close')} />
      </div>
    </FocusScope>
  );
};

Overlay.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NavPrimary;
