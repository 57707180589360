import PropTypes from 'prop-types';

const IconMoon = ({ className, fill }) => (
  <div className={`svg ico-moon ${className}`}>
    <svg width="17" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.4586C0.125117 11.4657 0.251183 11.4694 0.378096 11.4694C3.97093 11.4694 6.8835 8.55681 6.8835 4.96397C6.8835 2.97432 5.99029 1.19329 4.58298 0C7.99986 0.195812 10.7103 3.02868 10.7103 6.4946C10.7103 10.0874 7.79772 13 4.20489 13C2.6017 13 1.13396 12.4201 0 11.4586Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconMoon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconMoon.defaultProps = {
  className: '',
  fill: '',
};
export default IconMoon;
