import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectFrontPage } from '../../../redux/pagesSlice';

/** Hooks */
import useNavigateWithLoader from '../../../hooks/useNavigateWithLoader';

/** Components */
import Button from '../../Button';
import LogoUnipop from '../../../images/LogoUnipop';
import pictoUnipop from '../../../images/picto-unipop.svg';

const Logo = () => {
  const { navigate } = useNavigateWithLoader();
  const { i18n } = useTranslation();

  const frontPage = useSelector(selectFrontPage);

  return (
    <h1 className="logo header-logo">
      <Button onPress={() => navigate(frontPage.translations[i18n.language])}>
        <img src={pictoUnipop} alt="Université Populaire Luxembourg" />
        <LogoUnipop />
      </Button>
    </h1>
  );
};

export default Logo;
