import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMenuTriggerState } from '@react-stately/menu';
import { useMenuTrigger } from '@react-aria/menu';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';

/** Hooks */
import useCloseWithTransition from '../hooks/useCloseWithTransition';
import useOnScroll from '../hooks/useOnScroll';

/** Components */
import RadioGroup from './forms/RadioGroup';
import Button from './Button';
import Icon from './Icon';

const Pagination = ({ className, currentPage, numberPages, children, ...props }) => {
  const { t: __ } = useTranslation();

  return (
    <form aria-label={__('pagination.Pagination')}>
      <ul className={`pagination ${className}`}>
        <li>
          <Button
            className={`page-button ${currentPage === 1 ? 'is-disabled' : ''}`}
            icon="arrow-left"
            onPress={() => (currentPage > 1 ? props.onPrev() : () => {})}
            ariaLabel={__('pagination.Previous page')}
          />
        </li>
        {props.perPage && props.perPageValue && props.onChange && <li>{<PerPage {...props} />}</li>}
        <li>
          <Button
            className={`page-button ${currentPage === numberPages ? 'is-disabled' : ''}`}
            icon="arrow-right"
            onPress={() => (currentPage < numberPages ? props.onNext() : () => {})}
            ariaLabel={__('pagination.Next page')}
          />
        </li>
      </ul>
    </form>
  );
};

const PerPage = (props) => {
  const { t: __ } = useTranslation();

  const buttonRef = useRef();
  const menuState = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, menuState, buttonRef);
  const { buttonProps } = useButton(menuTriggerProps, buttonRef);

  return (
    <div className="filter">
      {menuState.isOpen && (
        <PerPageOverlay
          {...mergeProps(props, menuProps)}
          autoFocus={menuState.focusStrategy}
          onClose={() => menuState.close()}
        />
      )}
      <button {...buttonProps} ref={buttonRef} className="button overlay-button">
        <>
          {__('pagination.Per page')}
          <Icon icon="chevron-down" />
        </>
      </button>
    </div>
  );
};

const PerPageOverlay = ({ perPage, perPageValue, ...props }) => {
  const legendId = `f-${Math.round(Math.random() * 10000000000)}`;
  const { t: __ } = useTranslation();

  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 250);
  const { overlayProps } = useOverlay(
    {
      autoFocus: true,
      onClose: closeWithTransition,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );
  useOnScroll(closeWithTransition);

  return (
    <FocusScope restoreFocus>
      <fieldset ref={overlayRef} {...overlayProps} className="filter-overlay">
        <div className="filter-content">
          <Button className="close-button" icon="cross" onPress={closeWithTransition} ariaLabel={__('buttons.Close')} />
          <legend id={legendId} className="visually-hidden">
            {__('pagination.Per page')}
          </legend>
          <RadioGroup
            radios={perPage}
            value={perPageValue}
            aria-labelledby={legendId}
            onChange={(value) => {
              props.onChange(value);
              closeWithTransition();
            }}
          />
        </div>
        <DismissButton onDismiss={props.onClose} />
      </fieldset>
    </FocusScope>
  );
};

const perPageProps = {
  perPage: PropTypes.arrayOf(PropTypes.number),
  perPageValue: PropTypes.number,
  onChange: PropTypes.func,
};

PerPage.propTypes = perPageProps;

PerPageOverlay.propTypes = {
  ...perPageProps,
  onClose: PropTypes.func.isRequired,
};

Pagination.propTypes = {
  ...perPageProps,
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  numberPages: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Pagination.defaultProps = {
  className: '',
};

export default Pagination;
