import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const featuredCoursesSlice = createSlice({
  name: 'featuredCourses',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    currentCourse: {},
  },
  reducers: {
    /**
     * Save the courses list
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The courses list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload.sort((a, b) => (Date.parse(a.start_date) > Date.parse(b.start_date) ? 1 : -1));
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current course
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The current course
     */
    saveCurrentCourse: (state, action) => {
      state.currentCourse = action.payload;
    },
  },
});

export const { saveList, saveError, saveCurrentCourse } = featuredCoursesSlice.actions;

/**
 * Return the courses list filtered by a given language
 *
 * @param {string} language  The course language
 * @return {Object}
 */
export const selectList = createSelector(
  (state) => state.featuredCourses,
  (_, language) => language,
  (featuredCourses, language) => featuredCourses.list.filter((course) => course.lang === language)
);

/**
 * Return a slice the courses list filtered by a given language
 *
 * @param {string} language  The course language
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @return {Object}
 */
export const selectSlice = createSelector(
  (state) => state.featuredCourses,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (featuredCourses, language, start, length) =>
    featuredCourses.list
      .filter((course) => course.lang === language)
      .slice(start || 0, length ? (start || 0) + length : undefined)
);

export default featuredCoursesSlice.reducer;
