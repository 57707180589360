import PropTypes from 'prop-types';

export const pageListItemPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  date: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  featured_media: PropTypes.oneOfType([
    PropTypes.shape({
      w3840h2560: PropTypes.string.isRequired,
      w2880h1920: PropTypes.string.isRequired,
      w1920h1280: PropTypes.string.isRequired,
      w1440h960: PropTypes.string.isRequired,
      w960h640: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      w1920h2300: PropTypes.string.isRequired,
      w1440h1725: PropTypes.string.isRequired,
      w960h1150: PropTypes.string.isRequired,
    }),
  ]),
  parent: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  menu_order: PropTypes.number,
  lang: PropTypes.string.isRequired,
  translations: PropTypes.objectOf(PropTypes.string).isRequired,
  api_url: PropTypes.string.isRequired,
  cta: PropTypes.string,
  start_date: PropTypes.string, // yyyy-mm-dd, only for `featured_course` CPT
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
}).isRequired;

export const pageContentPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  featured_media: PropTypes.string,
  parent: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  acf: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  lang: PropTypes.string.isRequired,
  translations: PropTypes.objectOf(PropTypes.string).isRequired,
  api_url: PropTypes.string.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.string,
      link: PropTypes.string,
    })
  ), // Only for `featured_course` CPT
  meta_data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
}).isRequired;

const trainingAddressPropTypes = PropTypes.shape({
  codePostal: PropTypes.string,
  localite: PropTypes.string.isRequired,
  nom: PropTypes.string.isRequired,
  paysCode: PropTypes.string,
  paysNom: PropTypes.string,
  rueNumero: PropTypes.string,
});

export const trainingContentPropTypes = PropTypes.shape({
  adresseCours: trainingAddressPropTypes.isRequired,
  adresseInscription: trainingAddressPropTypes,
  anneeScolaire: PropTypes.string.isRequired,
  categorieCode: PropTypes.string.isRequired,
  categorieNom: PropTypes.string.isRequired,
  coursId: PropTypes.string.isRequired,
  coursType: PropTypes.oneOf(['CLASSIQUE', 'VE', 'L45']).isRequired,
  cout: PropTypes.number.isRequired,
  dateDebut: PropTypes.string.isRequired,
  dateFin: PropTypes.string.isRequired,
  dateInscription: PropTypes.string,
  description: PropTypes.string,
  domainesCompetences: PropTypes.arrayOf(
    PropTypes.shape({
      competences: PropTypes.arrayOf(PropTypes.string),
      domaine: PropTypes.string.isRequired,
    })
  ),
  duree: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  horairePrevu: PropTypes.string.isRequired,
  horaires: PropTypes.arrayOf(
    PropTypes.shape({
      duree: PropTypes.string,
      heure: PropTypes.string,
      jour: PropTypes.string,
    })
  ).isRequired,
  intitule: PropTypes.string.isRequired,
  langueCoursCode: PropTypes.string.isRequired,
  langueCoursNom: PropTypes.string.isRequired,
  matiereCode: PropTypes.string.isRequired,
  matiereNom: PropTypes.string.isRequired,
  nbInscrits: PropTypes.number.isRequired,
  nbPlaces: PropTypes.number.isRequired,
  niveau: PropTypes.string,
  onlineRegistration: PropTypes.bool.isRequired,
  onlineRegistrationUrl: PropTypes.string,
  organisateur: PropTypes.shape({
    adresse: trainingAddressPropTypes,
    code: PropTypes.string.isRequired,
    fax: PropTypes.string,
    mail: PropTypes.string.isRequired,
    nom: PropTypes.string.isRequired,
    nomContact: PropTypes.string,
    onlineReg: PropTypes.bool.isRequired,
    prenomContact: PropTypes.string,
    statut: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    websiteUrl: PropTypes.string,
  }).isRequired,
  prerequis: PropTypes.string,
  renseignements: PropTypes.string,
}).isRequired;

const propTypes = {
  page: pageListItemPropTypes,
  content: pageContentPropTypes,
};

export default propTypes;
