import { useState, useEffect } from 'react';

/** Utils */
import { breakpointUp } from '../util/Breakpoint';

/**
 * useResponsiveImage Hook
 *
 * Return the url of an image of the given image array regarding window width
 *
 * @param {Array} image  The image array keyed by image sizes
 * @param {string} format (pageHeader|landscape|portrait|square) The image format
 * @return {string}  The image url
 */
const useResponsiveImage = (image, format) => {
  /**
   * Get the image size within the image array
   *
   * @param {Array} image  The image array keyed by image sizes
   * @param {string} format (pageHeader|landscape|portrait|square) The image format
   * @return {string}  The image url
   */
  const getImage = (image, format) => {
    if (image) {
      switch (format) {
        case 'pageHeader':
          if (window.matchMedia(breakpointUp('xxl')).matches) {
            return image['w3840h1040'];
          } else if (window.matchMedia(breakpointUp('xl')).matches) {
            return image['w2880h780'];
          } else if (window.matchMedia(breakpointUp('md')).matches) {
            return image['w1920h520'];
          } else if (window.matchMedia(breakpointUp('sm')).matches) {
            return image['w1440h390'];
          } else {
            return image['w960h260'];
          }
        case 'landscape':
          if (window.matchMedia(breakpointUp('xxl')).matches) {
            return image['w3840h2560'];
          } else if (window.matchMedia(breakpointUp('xl')).matches) {
            return image['w2880h1920'];
          } else if (window.matchMedia(breakpointUp('md')).matches) {
            return image['w1920h1280'];
          } else if (window.matchMedia(breakpointUp('sm')).matches) {
            return image['w1440h960'];
          } else {
            return image['w960h640'];
          }
        case 'portrait':
          if (window.matchMedia(breakpointUp('md')).matches) {
            return image['w1920h2300'];
          } else if (window.matchMedia(breakpointUp('sm')).matches) {
            return image['w1440h1725'];
          } else {
            return image['w960h1150'];
          }
        case 'square':
          if (window.matchMedia(breakpointUp('md')).matches) {
            return image['w1920h1920'];
          } else if (window.matchMedia(breakpointUp('sm')).matches) {
            return image['w1440h1440'];
          } else {
            return image['w960h960'];
          }
        default:
          break;
      }
    }
  };

  /** Default image */
  const [responsiveImage, setResponsiveImage] = useState(getImage(image, format));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /** Set window width */
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /** Set responsive image if window width changed */
  useEffect(() => {
    setResponsiveImage(getImage(image, format));
  }, [windowWidth, image, format]);

  /** Bind/unbind the resize handler */
  useEffect(() => {
    /**
     * Resize handler
     *
     * @param {CustomEvent|Event} e
     */
    const onResize = (e) => {
      if (!window.customResize || e.detail.width) {
        setResponsiveImage(getImage(image, format));
      }
    };
    window.addEventListener(window.customResize ? 'custom-resize' : 'resize', onResize);
    return () => window.removeEventListener(window.customResize ? 'custom-resize' : 'resize', onResize);
  }, [image, format]);

  /** Return the image url */
  return responsiveImage;
};

export { useResponsiveImage };
export default useResponsiveImage;
