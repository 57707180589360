import PropTypes from 'prop-types';

const IconClose = ({ className, fill }) => (
  <div className={`svg ico-close ${className}`}>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3353 1.79413C12.7024 1.42702 12.7024 0.831814 12.3353 0.464702V0.464702C11.9682 0.0975904 11.373 0.0975905 11.0059 0.464702L7.10715 4.36345C6.71663 4.75398 6.08347 4.75398 5.69294 4.36345L1.79419 0.464702C1.42708 0.0975902 0.831875 0.0975904 0.464763 0.464702V0.464702C0.0976514 0.831814 0.0976516 1.42702 0.464763 1.79413L4.36351 5.69288C4.75404 6.08341 4.75404 6.71657 4.36351 7.10709L0.464763 11.0058C0.0976512 11.373 0.0976514 11.9682 0.464763 12.3353V12.3353C0.831875 12.7024 1.42708 12.7024 1.79419 12.3353L5.69294 8.43652C6.08347 8.046 6.71663 8.046 7.10716 8.43652L11.0059 12.3353C11.373 12.7024 11.9682 12.7024 12.3353 12.3353V12.3353C12.7024 11.9682 12.7024 11.373 12.3353 11.0058L8.43658 7.10709C8.04606 6.71657 8.04606 6.08341 8.43658 5.69288L12.3353 1.79413Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconClose.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconClose.defaultProps = {
  className: '',
  fill: '#0D1C55',
};

export default IconClose;
