import PropTypes from 'prop-types';

const IconSun = ({ className, fill }) => (
  <div className={`svg ico-sun ${className}`}>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.79167 2.83334V1.41668C7.79167 1.02709 8.11042 0.708344 8.5 0.708344C8.88958 0.708344 9.20833 1.02709 9.20833 1.41668V2.83334C9.20833 3.22293 8.88958 3.54168 8.5 3.54168C8.11042 3.54168 7.79167 3.22293 7.79167 2.83334ZM13.005 4.99376L14.0038 3.98793C14.28 3.71168 14.28 3.26543 14.0038 2.98918C13.7275 2.71293 13.2813 2.71293 13.005 2.98918L12.0063 3.99501C11.73 4.27126 11.73 4.71751 12.0063 4.99376C12.2825 5.27001 12.7288 5.27001 13.005 4.99376ZM15.5833 7.79168H14.1667C13.7771 7.79168 13.4583 8.11043 13.4583 8.50001C13.4583 8.88959 13.7771 9.20834 14.1667 9.20834H15.5833C15.9729 9.20834 16.2917 8.88959 16.2917 8.50001C16.2917 8.11043 15.9729 7.79168 15.5833 7.79168ZM8.5 13.4583C8.11042 13.4583 7.79167 13.7771 7.79167 14.1667V15.5833C7.79167 15.9729 8.11042 16.2917 8.5 16.2917C8.88958 16.2917 9.20833 15.9729 9.20833 15.5833V14.1667C9.20833 13.7771 8.88958 13.4583 8.5 13.4583ZM3.995 4.99376L2.98917 3.99501C2.71292 3.71876 2.71292 3.26543 2.98917 2.99626C3.26542 2.72709 3.71875 2.72001 3.98792 2.99626L4.98667 3.99501C5.26292 4.27126 5.26292 4.72459 4.98667 4.99376C4.71042 5.26293 4.26417 5.27001 3.995 4.99376ZM12.0063 12.0063C11.73 12.2825 11.73 12.7358 12.0063 13.005L13.005 14.0038C13.2813 14.28 13.7346 14.28 14.0038 14.0038C14.28 13.7275 14.28 13.2742 14.0038 13.005L13.005 12.0063C12.7358 11.73 12.2825 11.73 12.0063 12.0063ZM1.41667 9.20834H2.83333C3.22292 9.20834 3.54167 8.88959 3.54167 8.50001C3.54167 8.11043 3.22292 7.79168 2.83333 7.79168H1.41667C1.02708 7.79168 0.708334 8.11043 0.708334 8.50001C0.708334 8.88959 1.02708 9.20834 1.41667 9.20834ZM3.995 14.0108L4.99375 13.0121C5.27 12.7358 5.27 12.2825 4.99375 12.0133C4.7175 11.7442 4.26417 11.7371 3.995 12.0133L2.99625 13.0121C2.72 13.2883 2.72 13.7417 2.99625 14.0108C3.26542 14.2871 3.71875 14.2871 3.995 14.0108ZM8.5 4.25001C6.15542 4.25001 4.25 6.15543 4.25 8.50001C4.25 10.8446 6.15542 12.75 8.5 12.75C10.8446 12.75 12.75 10.8446 12.75 8.50001C12.75 6.15543 10.8446 4.25001 8.5 4.25001Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconSun.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconSun.defaultProps = {
  className: '',
  fill: '',
};
export default IconSun;
