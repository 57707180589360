import PropTypes from 'prop-types';

const LogoUnipop = ({ className }) => (
  <div className={`svg logo-unipop ${className}`}>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 181" xmlSpace="preserve">
      <g id="text">
        <path
          id="luxembourg"
          className="text"
          d="M297.53,133.25v-1.5h-12.88v-20.44h-1.63v21.94H297.53z M319.6,123.94v-12.63h-1.63v12.82
            c0,5.3-2.85,7.99-7.3,7.99c-4.64,0-7.43-2.98-7.43-8.15v-12.66h-1.63v12.82c0,6.14,3.73,9.47,9,9.47
            C315.94,133.59,319.6,130.24,319.6,123.94L319.6,123.94z M343.39,133.25l-8.71-11.22l8.4-10.72h-1.91l-7.46,9.65l-7.49-9.65h-1.94
            l8.43,10.75l-8.75,11.19h1.92l7.77-10.12l7.8,10.12H343.39z M364.17,133.25v-1.5h-14.14v-8.81h12.57v-1.5h-12.57v-8.62h13.98v-1.5
            H348.4v21.94L364.17,133.25L364.17,133.25z M390.25,133.25v-21.94h-1.54l-8.81,12.98l-8.81-12.98h-1.54v21.94h1.57v-19.06
            l8.68,12.63h0.13l8.68-12.63v19.06H390.25z M414.33,127.26v-0.06c0-3.04-2.32-4.54-4.95-5.23c2.25-0.74,3.76-2.84,3.74-5.21v-0.06
            c0-3.29-2.85-5.39-7.11-5.39h-9.06v21.94h9.53C411.19,133.25,414.33,130.93,414.33,127.26L414.33,127.26z M411.44,116.92
            c0,2.92-2.35,4.51-5.64,4.51h-7.24v-8.62h7.4c3.51,0,5.49,1.63,5.49,4.04L411.44,116.92z M412.66,127.26
            c0,2.76-2.38,4.48-6.14,4.48h-7.96v-8.81h7.43c4.33,0,6.68,1.57,6.68,4.26L412.66,127.26z M440.81,122.28
            c0.03-0.03,0.03-0.03,0-0.06c0-5.99-4.36-11.28-11-11.28c-6.1-0.01-11.05,4.92-11.07,11.02c0,0.11,0,0.22,0,0.33v0.06
            c0,5.99,4.36,11.28,11,11.28c6.1,0.01,11.05-4.92,11.07-11.02C440.82,122.5,440.82,122.39,440.81,122.28z M439.12,122.34
            c0,5.42-3.89,9.81-9.31,9.81s-9.37-4.45-9.37-9.87v-0.06c0-5.42,3.89-9.81,9.31-9.81s9.37,4.45,9.37,9.87V122.34z M464.17,123.94
            v-12.63h-1.63v12.82c0,5.3-2.85,7.99-7.3,7.99c-4.64,0-7.43-2.98-7.43-8.15v-12.66h-1.63v12.82c0,6.14,3.73,9.47,9,9.47
            C460.5,133.59,464.17,130.24,464.17,123.94z M488.37,133.25l-7.24-9.37c3.7-0.5,6.43-2.6,6.43-6.27v-0.06
            c0-3.67-3.1-6.24-7.84-6.24h-9.22v21.94h1.63v-9.09h7.18l6.99,9.09H488.37z M485.92,117.67c0,3.16-2.73,5.02-6.39,5.02h-7.4v-9.87
            h7.49c3.98,0,6.3,1.85,6.3,4.8L485.92,117.67z M512,130.34v-8.46h-8.9v1.5h7.34v6.3c-1.98,1.6-4.44,2.49-6.99,2.51
            c-5.86,0-9.4-4.23-9.4-9.91v-0.07c0-5.3,3.73-9.81,9.06-9.81c2.61-0.09,5.14,0.86,7.05,2.63l1.07-1.22
            c-2.21-1.95-5.08-2.99-8.02-2.88c-6.55,0-10.85,5.42-10.85,11.35v0.06c0,6.17,4.11,11.28,11.03,11.28
            C506.56,133.6,509.62,132.43,512,130.34z"
        />
        <g id="populaire" transform="translate(0.266 40.029)">
          <path
            id="p1"
            className="text"
            d="M282.26,35.26h10.97c6.41,0,10.56,3.28,10.56,8.93v0.07c0,6.05-4.63,9.22-10.93,9.22h-3.53v7.29
			        h-7.07V35.26z M292.72,47.94c2.48,0,4.04-1.31,4.04-3.35v-0.07c0-2.19-1.57-3.32-4.08-3.32h-3.35v6.74L292.72,47.94z"
          />
          <path
            id="p2"
            className="text"
            d="M307.41,48.09v-0.07c0-7.32,5.9-13.26,13.77-13.26s13.7,5.9,13.7,13.19v0.08
              c0,7.32-5.9,13.26-13.77,13.26S307.41,55.38,307.41,48.09z M327.63,48.09v-0.07c0.17-3.63-2.63-6.71-6.26-6.88
              c-0.09,0-0.18-0.01-0.26-0.01c-3.59,0.01-6.5,2.93-6.49,6.52c0,0.1,0,0.19,0.01,0.29v0.08c-0.13,3.67,2.74,6.75,6.4,6.88
              c0.05,0,0.1,0,0.15,0c3.59-0.03,6.48-2.96,6.45-6.55C327.63,48.27,327.63,48.18,327.63,48.09L327.63,48.09z"
          />
          <path
            id="p3"
            className="text"
            d="M339.99,35.26h10.97c6.41,0,10.56,3.28,10.56,8.93v0.07c0,6.05-4.63,9.22-10.93,9.22h-3.53v7.29
			        h-7.07V35.26z M350.45,47.94c2.48,0,4.04-1.31,4.04-3.35v-0.07c0-2.19-1.57-3.32-4.08-3.32h-3.35v6.74L350.45,47.94z"
          />
          <path
            id="p4"
            className="text"
            d="M365.69,49.58V35.26h7.14v14.17c0,3.68,1.82,5.43,4.7,5.43c2.92,0,4.74-1.64,4.74-5.24V35.26h7.14
			        V49.4c0,8.2-4.7,11.84-11.95,11.84C370.25,61.24,365.69,57.6,365.69,49.58z"
          />
          <path id="p5" className="text" d="M395.07,35.26h7.07v19.31h12.35v6.19h-19.42V35.26z" />
          <path
            id="p6"
            className="text"
            d="M427.83,35.08h6.81l10.86,25.69h-7.58l-1.82-4.59h-9.87l-1.82,4.59h-7.39L427.83,35.08z
			        M434.02,50.68l-2.84-7.22l-2.88,7.22H434.02z"
          />
          <path id="p7" className="text" d="M449.63,35.26h7.07v25.5h-7.07V35.26z" />
          <path
            id="p8"
            className="text"
            d="M462.82,35.26h12.06c3.93,0,6.59,1.02,8.31,2.73c1.53,1.59,2.35,3.73,2.26,5.94V44
			        c0.14,3.48-1.97,6.66-5.24,7.87l6.08,8.89h-8.13l-5.13-7.72h-3.14v7.72h-7.07L462.82,35.26z M474.59,47.5
			        c2.37,0,3.79-1.17,3.79-3.02v-0.07c0-2.04-1.49-3.06-3.83-3.06h-4.66v6.16L474.59,47.5L474.59,47.5z"
          />
          <path
            id="p9"
            className="text"
            d="M490.49,35.26H511v5.98h-13.52v3.9h12.24v5.57h-12.24v4.04h13.7v6.01h-20.7L490.49,35.26
			        L490.49,35.26z"
          />
        </g>
        <g id="universite">
          <path
            id="u1"
            className="text"
            d="M282.25,53.83V40.2h6.8v13.49c0,3.5,1.74,5.17,4.48,5.17c2.77,0,4.51-1.56,4.51-4.99V40.2h6.8v13.46
			        c0,7.8-4.47,11.27-11.37,11.27C286.59,64.92,282.25,61.45,282.25,53.83z"
          />
          <path
            id="u2"
            className="text"
            d="M310.21,40.2h6.28l9.99,12.83V40.2h6.66v24.27h-5.86l-10.4-13.32v13.32h-6.66L310.21,40.2z"
          />
          <path id="u3" className="text" d="M339.01,40.2h6.73v24.27h-6.73V40.2z" />
          <path
            id="u4"
            className="text"
            d="M349.66,40.2h7.49l5.51,15.36l5.54-15.36h7.35l-9.85,24.45h-6.24L349.66,40.2z"
          />
          <path
            id="u5"
            className="text"
            d="M379.32,40.2h19.53v5.69h-12.87v3.71h11.65v5.31h-11.65v3.85h13.04v5.72h-19.7V40.2z"
          />
          <path
            id="u6"
            className="text"
            d="M403.88,40.2h11.48c3.75,0,6.28,0.97,7.91,2.6c1.46,1.51,2.23,3.55,2.15,5.65v0.07
			        c0.14,3.32-1.88,6.34-4.99,7.49l5.79,8.46h-7.73l-4.89-7.35h-2.98v7.35h-6.73L403.88,40.2L403.88,40.2z M415.08,51.85
			        c2.26,0,3.61-1.11,3.61-2.88V48.9c0-1.94-1.42-2.91-3.64-2.91h-4.44v5.86L415.08,51.85z"
          />
          <path
            id="u7"
            className="text"
            d="M428.38,60.87l3.75-4.47c2.21,1.84,4.99,2.87,7.86,2.9c1.84,0,2.81-0.59,2.81-1.66v-0.07
			        c0-1.04-0.8-1.53-4.13-2.36c-5.13-1.18-9.15-2.6-9.15-7.59v-0.07c0-4.54,3.57-7.77,9.4-7.77c3.61-0.14,7.15,1,9.99,3.23
			        l-3.36,4.75c-1.95-1.5-4.34-2.34-6.8-2.39c-1.63,0-2.43,0.63-2.43,1.56V47c0,1.07,0.84,1.63,4.23,2.36
			        c5.54,1.21,9.02,3.02,9.02,7.56v0.07c0,4.96-3.92,7.91-9.82,7.91C435.59,64.98,431.55,63.55,428.38,60.87z"
          />
          <path id="u8" className="text" d="M454.39,40.2h6.73v24.27h-6.73V40.2z" />
          <path id="u9" className="text" d="M472.88,46.09h-7.25v-5.9h21.26v5.9h-7.28v18.38h-6.73V46.09z" />
          <path
            id="u10"
            className="text"
            d="M491.23,40.2h19.52v5.69h-12.87v3.71h11.65v5.31h-11.65v3.85h13.04v5.72h-19.7V40.2H491.23z
			        M502.22,32.91l6.28,1.91l-5.13,3.71h-4.89L502.22,32.91z"
          />
        </g>
      </g>
      <g id="picto">
        <path
          id="p"
          className="secondary"
          d="M245.43,0.29v86.4c0,61.71-31.56,94.02-85.9,94.02h-0.7c-58.2,0-88.7-36.88-88.7-97.53V40.25H0V0.29
		        H245.43z"
        />
        <path
          id="u"
          className="primary"
          d="M109.25,95.92V40.28h34.31V95.5c0,12.54,6.48,17.88,15.57,17.88c9.23,0,15.57-4.9,15.57-17.15V40.27
		        h34.31V95.2c0,34.46-20.04,48.01-50.17,48.01C128.86,143.21,109.25,129.37,109.25,95.92z"
        />
      </g>
    </svg>
  </div>
);

LogoUnipop.propTypes = {
  className: PropTypes.string,
};

LogoUnipop.defaultProps = {
  className: '',
};
export default LogoUnipop;
