import PropTypes from 'prop-types';

const IconSend = ({ className, fill }) => (
  <div className={`svg ico-questionmark ${className}`}>
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-0.000350979 0.621433L2.51875 9.99029L13.3037 11.5137L20.2894 11.5137C21.351 11.5137 21.6811 10.0776 20.7261 9.61406L0.944313 0.0120758C0.944313 0.0120758 0.520765 -0.0447831 0.314537 0.0882454C0.108307 0.221274 -0.000350979 0.621433 -0.000350979 0.621433Z"
        fill={fill}
      />
      <path
        d="M-0.000350925 21.3786L2.51876 12.0097L13.3037 10.4863L20.2894 10.4863C21.351 10.4863 21.6811 11.9224 20.7261 12.3859L0.944313 21.9879C0.944313 21.9879 0.520765 22.0448 0.314537 21.9118C0.108307 21.7787 -0.000350925 21.3786 -0.000350925 21.3786Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconSend.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconSend.defaultProps = {
  className: '',
  fill: '#0D1C55',
};

export default IconSend;
