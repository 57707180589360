/**
 * Format a date
 *
 * @param {string} date (dd/mm/yyyy)  The date to format
 * @param {string} inputFormat  The format of the input date (*)
 * @return {string} (dd.mm.yy)  The formatted date
 * (*) If not "dd/mm/yyyy" the format of the date is supposed to be standard ("yyyy-mm-ddThh:mm:ss" or "yyyy-mm-dd")
 */
export const formatDate = (date, inputFormat = 'dd/mm/yyyy') => {
  const dateArray = inputFormat === 'dd/mm/yyyy' ? date.split('/') : date.split('T')[0].split('-').reverse();
  dateArray[2] = dateArray[2].substring(2);
  return dateArray.join('.');
};

/**
 * Convert a string date to a Date object
 *
 * @param {string} date (dd/mm/YYYY)  The date to convert
 * @return {Date}  The converted date
 */
export const stringToDate = (date) => {
  const [d, m, y] = date.split('/');
  return new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
};

/**
 * Convert a Date object to a string
 *
 * @param {Date} date  The date object
 * @return {string}  (dd/mm/YYYY) The converted date
 */
export const dateToString = (date) => {
  const addZero = (number) => `${number < 10 ? '0' : ''}${number.toString()}`;
  return `${addZero(date.getDate())}/${addZero(date.getMonth() + 1)}/${date.getFullYear()}`;
};
