import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';

/** PropTypes */
import { trainingContentPropTypes } from './templates/propTypes';

/** Redux */
import { selectPage } from '../redux/pagesSlice';

/** Hooks */
import useNavigateWithLoader from '../hooks/useNavigateWithLoader';

/** Utils */
import { formatDate } from '../util/Date';
import { LessonsToSessions, UpdateInstitutions } from '../util/Helper';

/** Components */
import Icon from './Icon';
import Link from './Link';

const TrainingsTable = forwardRef(({ trainingsList, ...props }, tableRef) => {
  const { t: __, i18n } = useTranslation();

  const pageTrainings = useSelector((state) => selectPage(state, 'trainings', i18n.language));

  return (
    <>
      {trainingsList.length > 0 ? (
        <table ref={tableRef} className="table trainings-table" aria-label={__('templates.trainings.Trainings list')}>
          <thead className="visually-hidden">
            <tr>
              <th>{__('templates.trainings.Dates')}</th>
              <th>{__('templates.trainings.Title')}</th>
              <th>{__('templates.trainings.Level')}</th>
              <th>{__('templates.trainings.Duration')}</th>
              <th>{__('templates.trainings.Promoter')}</th>
              <th>{__('templates.trainings.Remaining Places')}</th>
              <th>{__('templates.trainings.Price')}</th>
              <th>{__('templates.trainings.Seats')}</th>
            </tr>
          </thead>
          <tbody>
            {trainingsList.map((training, index) => (
              <TrainingsTableRow key={index} {...props} training={training} pageTrainingLink={pageTrainings.link} />
            ))}
          </tbody>
        </table>
      ) : (
        <p ref={tableRef} className="trainings-table-no-result">
          {__('templates.trainings.No trainings matches your search')}
        </p>
      )}
    </>
  );
});

const TrainingsTableRow = ({ training, pageTrainingLink, ...props }) => {
  const { navigate } = useNavigateWithLoader();
  const { t: __ } = useTranslation();

  const singleTrainingLink = `${pageTrainingLink}${kebabCase(training.intitule)}-${kebabCase(
    training.niveau
  )}-${kebabCase(training.coursId)}-${kebabCase(training.anneeScolaire)}/`;

  const realDuree = LessonsToSessions(training);
  const orgName = UpdateInstitutions(training);

  return (
    <tr
      onClick={() => {
        props.onNavigate();
        navigate(singleTrainingLink);
      }}
    >
      <td className="table-cell-dates">
        {formatDate(training.dateDebut)} - {formatDate(training.dateFin)}
      </td>
      <td className="table-cell-title">
        <Link url={singleTrainingLink}>
          <span>{training.intitule}</span>
        </Link>
      </td>
      <td className="table-cell-level">
        <span>{training.niveau}</span>
      </td>
      <td className="table-cell-duration">
        {realDuree && (
          <>
            <Icon icon="clock" />
            {realDuree}
          </>
        )}
      </td>
      <td className="table-cell-promoter">{orgName}</td>
      <td className="table-cell-location">
        {training.adresseCours && (
          <>
            <Icon icon="marker" />
            {training.adresseCours.localite}
          </>
        )}
      </td>
      <td className="table-cell-price">{training.cout > 0 && <>{training.cout}&nbsp;€</>}</td>
      <td className="table-cell-seats">
        {training.nbPlaces && (
          <>
            <span>{training.nbPlaces - training.nbInscrits}</span>&nbsp;{__('templates.trainings.seats')}
          </>
        )}
      </td>
    </tr>
  );
};

TrainingsTable.displayName = 'TrainingsTable';

TrainingsTable.propTypes = {
  trainingsList: PropTypes.arrayOf(trainingContentPropTypes),
  onNavigate: PropTypes.func,
};

TrainingsTableRow.propTypes = {
  training: trainingContentPropTypes,
  pageTrainingLink: PropTypes.string.isRequired,
  onNavigate: PropTypes.func,
};

TrainingsTableRow.defaultProps = {
  onNavigate: () => {},
};

export default TrainingsTable;
