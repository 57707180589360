(() => {
  class CustomResize {
    /**
     * window.resize handler, delayed for better performance
     * Fires `custom-resize` custom event on `window` element
     * Event detail object shows if width / heigth has been resized
     *
     * @fires window#custom-resize
     */
    constructor() {
      this.delayed = false;
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.windowHeight;
      if (!window.customResize) {
        window.addEventListener('resize', this.onResize.bind(this));
      }
      window.customResize = true;
    }

    /**
     * Delayed window.resize handler
     */
    onResize() {
      if (!this.delayed) {
        this.delayed = true;
        setTimeout(() => {
          this.delayed = false;
          if (this.windowWidth !== window.innerWidth || this.windowHeight !== window.windowHeight) {
            /**
             * Dispatch custom-resize event
             *
             * @event window#custom-resize
             * @type {Object}
             * @property {Boolean} width indicates if the width has been changed
             * @property {Boolean} height indicates if the height has been changed
             */
            window.dispatchEvent(
              new CustomEvent('custom-resize', {
                detail: {
                  width: this.windowWidth !== window.innerWidth,
                  height: this.windowHeight !== window.windowHeight,
                },
              })
            );

            /** Store new sizes */
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.windowHeight;
          }
        }, 100);
      }
    }
  }
  new CustomResize();
})();
