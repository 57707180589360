/**
 * Scroll to an element on the page
 *
 * @param {string} id The id of the element to scroll to
 * @return {void}
 */
export const ScrollToElement = (id) => {
  const scrollTo = () => {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 150);
    } else {
      requestAnimationFrame(scrollTo);
    }
  };

  const observer = new MutationObserver(() => {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 150);
      observer.disconnect();
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
  scrollTo();
};
