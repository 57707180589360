import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMenuTriggerState } from '@react-stately/menu';
import { useMenuTrigger } from '@react-aria/menu';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';
import { Item } from '@react-stately/collections';

/** Redux */
import { selectTrainingPages } from '../../redux/pagesSlice';

/** Hooks */
import useNavigateWithLoader from '../../hooks/useNavigateWithLoader';
import useCloseWithTransition from '../../hooks/useCloseWithTransition';
import useOnScroll from '../../hooks/useOnScroll';

/** Components */
import Menu from '../Menu';
import Icon from '../Icon';
import Link from '../Link';

const TrainingsMenu = (props) => {
  const { t: __ } = useTranslation();

  const buttonRef = useRef();
  const menuState = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, menuState, buttonRef);
  const { buttonProps } = useButton(menuTriggerProps, buttonRef);

  return (
    <>
      <button {...buttonProps} ref={buttonRef} className="button overlay-button">
        {__('menu.Our trainings')}
        <Icon icon="chevron-down" />
      </button>
      {menuState.isOpen && (
        <Overlay props={menuProps} autoFocus={menuState.focusStrategy} onClose={() => menuState.close()} />
      )}
    </>
  );
};

const Overlay = (props) => {
  const { navigate } = useNavigateWithLoader();
  const { t: __, i18n } = useTranslation();

  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 250);
  const { overlayProps } = useOverlay(
    {
      onClose: closeWithTransition,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );
  useOnScroll(closeWithTransition);

  const trainingsPages = useSelector((state) => selectTrainingPages(state, i18n.language));

  return (
    <FocusScope restoreFocus>
      <div ref={overlayRef} {...overlayProps} className="nav-overlay">
        <DismissButton onDismiss={props.onClose} />
        <Menu
          onAction={(link) => {
            closeWithTransition();
            navigate(link);
          }}
          aria-label={__('menu.Our trainings')}
        >
          {trainingsPages.map((page, index) => (
            <Item key={page.link} textValue={page.title}>
              <Link url={page.link} onPress={() => {}} title={page.title} />
            </Item>
          ))}
        </Menu>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
};

Overlay.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TrainingsMenu;
