import PropTypes from 'prop-types';

const IconPhone = ({ className, fill }) => (
  <div className={`svg ico-phone ${className}`}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconPhone.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconPhone.defaultProps = {
  className: '',
  fill: '#0D1C55',
};

export default IconPhone;
