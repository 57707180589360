import { configureStore } from '@reduxjs/toolkit';

import themeReducer from './themeSlice';
import formReducer from './formSlice';
import appReducer from './appSlice';
import contactsReducer from './contactsSlice';
import pagesReducer from './pagesSlice';
import newsReducer from './newsSlice';
import feedsReducer from './feedsSlice';
import featuredCoursesReducer from './featuredCoursesSlice';
import trainingsReducer from './trainingsSlice';

export default configureStore({
  reducer: {
    theme: themeReducer,
    app: appReducer,
    contacts: contactsReducer,
    form: formReducer,
    pages: pagesReducer,
    news: newsReducer,
    featuredCourses: featuredCoursesReducer,
    trainings: trainingsReducer,
    feeds: feedsReducer,
  },
});
