import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Item } from '@react-stately/collections';

/** Redux */
import { selectPage } from '../../redux/pagesSlice';

/** Components */
import Menu from '../Menu';
import Link from '../Link';

const MainMenu = ({ onAction }) => {
  const { t: __, i18n } = useTranslation();

  const pageUnipop = useSelector((state) => selectPage(state, 'unipop', i18n.language));
  const pageTrainings = useSelector((state) => selectPage(state, 'trainings', i18n.language));
  const pageProfessionalTraining = useSelector((state) => selectPage(state, 'professional-training', i18n.language));
  const pageFormalAdultEducation = useSelector((state) => selectPage(state, 'formal-adult-education', i18n.language));
  const pageFaq = useSelector((state) => selectPage(state, 'faq', i18n.language));
  // const pageInfos = useSelector((state) => selectPage(state, 'infos', i18n.language));

  return (
    <Menu onAction={onAction} aria-label={__('menu.Main menu')}>
      <Item key={pageUnipop.link} textValue={__('menu.UniPop')}>
        <Link url={pageUnipop.link} onPress={() => {}} title={__('menu.UniPop')} />
      </Item>
      <Item key={pageTrainings.link} textValue={__('menu.Our trainings')}>
        <Link url={pageTrainings.link} onPress={() => {}} title={__('menu.Our trainings')} />
      </Item>
      <Item key={pageProfessionalTraining.link} textValue={pageProfessionalTraining.title}>
        <Link url={pageProfessionalTraining.link} onPress={() => {}} title={pageProfessionalTraining.title} />
      </Item>
      <Item key={pageFormalAdultEducation.link} textValue={pageFormalAdultEducation.title}>
        <Link url={pageFormalAdultEducation.link} onPress={() => {}} title={pageFormalAdultEducation.title} />
      </Item>
      <Item key={pageFaq.link} textValue={__('menu.FAQ')}>
        <Link url={pageFaq.link} onPress={() => {}} title={__('menu.FAQ')} />
      </Item>
      {/* <Item key={pageInfos.link} textValue={__('menu.Infos')}>
        <Link url={pageInfos.link} onPress={() => {}} title={__('menu.Infos')} />
      </Item> */}
    </Menu>
  );
};

MainMenu.propTypes = {
  onAction: PropTypes.func.isRequired,
};

export default MainMenu;
