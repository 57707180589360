import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { description as siteName } from '../../package.json';
import _ from 'lodash';

/**
 * Updates the SEO tags for all pages.
 * @param {string} title Title for meta.
 * @returns {Object} The SEO Component
 */
export default function SEO({ title, description, url, lang, image }) {
  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  const fullTitle = decodeHtml(title);
  const fullDescription = decodeHtml(description);
  const fullLang = `${lang}-${lang.toUpperCase()}`;

  return (
    <Helmet>
      <html lang={fullLang} />

      {/* Standard metadata tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={fullDescription} />

      {/* Facebook tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={fullDescription} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content={_.replace(fullLang, '-', '_')} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={fullTitle} />

      {/* Twitter tags */}
      <meta name="twitter:creator" content={siteName} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={fullDescription} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
