import React, { useState, useEffect } from 'react';
import ContactBot from './ContactBot';
import ContactForm from './forms/ContactForm';
import IconContactbot from '../images/icon-contactbot';
import IconClose from '../images/icon-close';
import SocialShareButton from './SocialShareButton';

const ContactBotContainer = () => {
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: '',
  });
  const [isVisible, setIsVisible] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const ogTitle = document.querySelector('meta[property="og:title"]')?.content || '';
    const ogDescription = document.querySelector('meta[property="og:description"]')?.content || '';
    const ogImage = document.querySelector('meta[property="og:image"]')?.content || '';
    const currentUrl = window.location.href;

    setMetaData({
      ogTitle,
      ogDescription,
      ogImage,
      currentUrl,
    });
  }, []);

  return (
    <>
      <div className={`contactbot-container ${isVisible ? 'visible' : ''} ${showForm ? 'show-form' : ''}`}>
        <button onClick={handleToggle} className="close">
          <IconClose />
        </button>
        <ContactBot onShowForm={handleShowForm} />
        <ContactForm onHideBack={handleHideForm} />
      </div>
      <div className="contactbot-toggle">
        <SocialShareButton
          url={metaData.currentUrl}
          title={metaData.ogTitle}
          message={metaData.ogDescription}
          image={metaData.ogImage}
          displayType="icon"
          buttons={['facebook', 'linkedin', 'email', 'printable']}
        />
        <button onClick={handleToggle} className="contactbot-toggle-button">
          <IconContactbot onClick={handleToggle} />
        </button>
      </div>
    </>
  );
};

export default ContactBotContainer;
