import PropTypes from 'prop-types';
import { useState, useEffect, lazy, Suspense, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';
import SEO from './SEO';
import { description as siteName } from '../../package.json';

/** PropTypes */
import { pageListItemPropTypes } from './templates/propTypes';

/** Redux */
import { selectPage, saveCurrentPage } from '../redux/pagesSlice';
import {
  selectIsTrainingsListLoaded,
  saveCurrentTraining,
  updateDateFilter,
  updateOrganizerFilter,
  updateDomainFilter,
  updateSubjectFilter,
  updateLevelFilter,
} from '../redux/trainingsSlice';

/** Utils */
import { responseStatus } from '../util/Fetch';
import { ScrollToElement } from '../util/ScrollToElement';
import Loader from './Loader';

/** Hooks */
import useNavigateWithLoader from '../hooks/useNavigateWithLoader';

/** Components */
import Page404 from './templates/Page404';

const PagesRouter = memo(() => {
  process.env.NODE_ENV === 'development' && console.info('<PagesRouter />');

  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const pagesList = useSelector((state) => state.pages.list);
  const newsList = useSelector((state) => state.news.list);
  const featuredCoursesList = useSelector((state) => state.featuredCourses.list);
  const trainingsList = useSelector((state) => state.trainings.list);
  const isTrainingsListLoaded = useSelector(selectIsTrainingsListLoaded);
  const pageTrainings = useSelector((state) => selectPage(state, 'trainings', i18n.language));

  /**
   * Update the date filter from the URL if necessary
   */
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const dateFrom = query.get('dateFrom');
    const dateTo = query.get('dateTo');
    const organizerName = query.get('organizerName');
    const domain = query.get('domain');
    const subject = query.get('subject');
    const level = query.get('level');

    if (dateFrom && dateTo) {
      dispatch(updateDateFilter({ dateFrom, dateTo }));
    }

    if (organizerName) {
      const organizerNames = organizerName.split(',').map((name) => decodeURIComponent(name.trim()));
      dispatch(updateOrganizerFilter({ organizerNames }));
    }

    if (domain) {
      const domains = domain.split(',').map((name) => decodeURIComponent(name.trim()));
      dispatch(updateDomainFilter({ domains }));
    }

    if (subject) {
      const subjects = subject.split(',').map((name) => decodeURIComponent(name.trim()));
      dispatch(updateSubjectFilter({ subjects }));
    }

    if (level) {
      const levels = level.split(',').map((name) => decodeURIComponent(name.trim()));
      dispatch(updateLevelFilter({ levels }));
    }

    if (dateFrom || dateTo || organizerName || domain || subject || level) {
      ScrollToElement('formations');
    }
  }, [dispatch, location.search]);

  /**
   * Create the trainings routes
   */
  const trainingsRoutes = [];
  const trainingsRoutesFallback = []; // Old one but as a fallback
  Object.values(pageTrainings.translations).forEach((link) => {
    trainingsList.forEach((training) => {
      trainingsRoutes.push({
        link: `${link}${kebabCase(training.intitule)}-${kebabCase(training.niveau)}-${kebabCase(
          training.coursId
        )}-${kebabCase(training.anneeScolaire)}/`,
        training,
      });
      trainingsRoutesFallback.push({
        link: `${link}${kebabCase(training.coursId)}/`,
        training,
      });
    });
  });

  return (
    <Routes>
      {pagesList.map((page, index) => (
        <Route key={`page-${index}`} path={page.link} element={<PageRoute page={page} />} />
      ))}
      {newsList.map((news, index) => (
        <Route key={`news-${index}`} path={news.link} element={<PageRoute page={news} />} />
      ))}
      {featuredCoursesList.map((course, index) => (
        <Route key={`feat-${index}`} path={course.link} element={<PageRoute page={course} />} />
      ))}
      {trainingsRoutes.map((training, index) => (
        <Route
          key={`training-${index}`}
          path={training.link}
          element={<TrainingRoute url={training.link} training={training.training} />}
        />
      ))}
      {trainingsRoutesFallback.map((training, index) => (
        <Route
          key={`training-${index}`}
          path={training.link}
          element={<TrainingRoute url={training.link} training={training.training} />}
        />
      ))}
      {!isTrainingsListLoaded ? (
        Object.values(pageTrainings.translations).map((link, index) => (
          <Route key={`loading-${index}`} path={`${link}:trainingId`} element={<></>} />
        ))
      ) : (
        <Route path="*" element={<Page404 />} />
      )}
    </Routes>
  );
});

const PageRoute = ({ page }) => {
  process.env.NODE_ENV === 'development' && console.info('<PageRoute />');

  const dispatch = useDispatch();
  const { removeLoader } = useNavigateWithLoader();
  const [templateContent, setTemplateContent] = useState(null);
  const [metaData, setMetaData] = useState(null);

  /**
   * Save the current page to Redux
   */
  useEffect(() => {
    dispatch(saveCurrentPage(page));
    dispatch(saveCurrentTraining({}));
  }, [page, dispatch]);

  /**
   * Fetch the page content
   */
  useEffect(() => {
    setTemplateContent(null);

    fetch(page.api_url, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => {
        process.env.NODE_ENV === 'development' && console.info('<PageRoute /> - Page content fetched');

        /** Set the template content */
        setTemplateContent(response);

        /** Remove the loader */
        removeLoader();

        /** Update the page meta data */
        const data = response.meta_data;
        const fullTitle = data.title ? `${data.title} - ${siteName}` : siteName;
        const imageUrl = data.image || `${process.env.REACT_APP_APP_URL}social.png`;
        const fullUrl = data.link?.startsWith('http')
          ? data.link
          : `${window.location.protocol}//${window.location.host}${data.link}`;
        const meta = {
          title: fullTitle,
          description: data.description,
          image: imageUrl,
          lang: response.lang,
          url: fullUrl,
        };
        setMetaData(meta);
        return meta;
      })
      .catch(console.error);
  }, [page.api_url, removeLoader]);

  /**
   * Load the template
   */
  const Template = lazy(() => import(`./templates/${page.template.name}`));
  return (
    <Suspense fallback={<Loader />}>
      {metaData && (
        <SEO
          title={metaData.title}
          description={metaData.description}
          url={metaData.url}
          lang={metaData.lang}
          image={metaData.image}
        />
      )}
      {/* Conditionally render the template content once it has been fetched */}
      {templateContent && <Template page={page} content={templateContent} />}
    </Suspense>
  );
};

const TrainingRoute = ({ url, training }) => {
  process.env.NODE_ENV === 'development' && console.info('<TrainingRoute />');

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [metaData, setMetaData] = useState(null);
  const { removeLoader } = useNavigateWithLoader();

  let pageTrainings = useSelector((state) => selectPage(state, 'trainings', i18n.language));
  pageTrainings = { ...pageTrainings, template: { name: 'SingleTraining', id: 'single-training' } };

  /**
   * Save the current page to Redux
   */
  useEffect(() => {
    dispatch(saveCurrentPage(pageTrainings));
    dispatch(saveCurrentTraining(training));

    /** Remove the loader */
    removeLoader();
  }, [pageTrainings, training, dispatch, removeLoader]);

  /**
   * Fetch the trainings page content
   */
  useEffect(() => {
    fetch(pageTrainings.api_url, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => {
        process.env.NODE_ENV === 'development' && console.info('<TrainingRoute /> - Page content fetched');

        /** Update the page meta data */
        const data = response.meta_data;
        const fullTitle = `${training.intitule} - ${data.title} - ${siteName}`;
        const imageUrl = data.image || `${process.env.REACT_APP_APP_URL}social.png`;
        const fullUrl = data.link
          ? data.link.startsWith('http')
            ? data.link
            : `${window.location.protocol}//${window.location.host}${data.link}`
          : '';
        const meta = {
          title: fullTitle,
          description: data.description,
          image: imageUrl,
          lang: i18n.language,
          url: fullUrl,
        };
        setMetaData(meta);
        return meta;
      })
      .catch(console.error);
  }, [pageTrainings.api_url, training.intitule, i18n.language]);

  /**
   * Load the template
   */
  const Template = lazy(() => import(`./templates/${pageTrainings.template.name}`));

  return (
    <Suspense fallback={<Loader />}>
      {/* Render the meta tags */}
      {metaData && (
        <SEO
          title={metaData.title}
          description={metaData.description}
          url={metaData.url}
          lang={metaData.lang}
          image={metaData.image}
        />
      )}

      {/* Render the template for the training */}
      <Template training={training} page={pageTrainings} />
    </Suspense>
  );
};

PageRoute.propTypes = {
  page: pageListItemPropTypes,
};

TrainingRoute.propTypes = {
  url: PropTypes.string.isRequired,
  training: PropTypes.object.isRequired,
};

PagesRouter.displayName = 'PagesRouter';

export default PagesRouter;
