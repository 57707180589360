import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useSearchFieldState } from '@react-stately/searchfield';
import { useSearchField } from '@react-aria/searchfield';

/** Components */
import Button from '../Button';

const FieldSearch = (props) => {
  const fieldRef = useRef();
  const fieldState = useSearchFieldState(props);
  const { labelProps, inputProps, clearButtonProps } = useSearchField(props, fieldState, fieldRef);
  const { label, clearButtonLabel } = props;

  return (
    <div className="form-field form-field-search">
      <label {...labelProps}>{label}</label>
      <div className="form-field-control">
        <input {...inputProps} ref={fieldRef} />
        {fieldState.value !== '' && (
          <Button className="clear-button" {...clearButtonProps} icon="cross" ariaLabel={clearButtonLabel} />
        )}
      </div>
    </div>
  );
};

FieldSearch.propTypes = {
  label: PropTypes.string.isRequired,
  clearButtonLabel: PropTypes.string.isRequired,
};

export default FieldSearch;
