import PropTypes from 'prop-types';

const SocialIn = ({ className, fill = '#ffffff', role = 'presentation', onClick }) => (
  <svg
    role={role}
    className={className}
    fill={fill}
    width="800px"
    height="800px"
    viewBox="0 0 512 512"
    version="1.1"
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M116.504,500.219V170.654H6.975v329.564H116.504 L116.504,500.219z M61.751,125.674c38.183,0,61.968-25.328,61.968-56.953c-0.722-32.328-23.785-56.941-61.252-56.941 C24.994,11.781,0.5,36.394,0.5,68.722c0,31.625,23.772,56.953,60.53,56.953H61.751L61.751,125.674z M177.124,500.219 c0,0,1.437-298.643,0-329.564H286.67v47.794h-0.727c14.404-22.49,40.354-55.533,99.44-55.533 c72.085,0,126.116,47.103,126.116,148.333v188.971H401.971V323.912c0-44.301-15.848-74.531-55.497-74.531 c-30.254,0-48.284,20.38-56.202,40.08c-2.897,7.012-3.602,16.861-3.602,26.711v184.047H177.124L177.124,500.219z"></path>
  </svg>
);

SocialIn.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  role: PropTypes.string,
  onClick: PropTypes.func,
};

SocialIn.defaultProps = {
  className: '',
  fill: '#ffffff',
  role: 'presentation',
  onClick: () => {},
};

export default SocialIn;
