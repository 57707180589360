/**
 * Normalize a string to unicode
 *
 * @param {string} string  The string to normalize
 * @return {string}  The normalized string
 */
export const normalizeString = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Format the first letter of a string to uppercase, and the rest to lowercase
 *
 * @param {string} param0  The string to format
 * @return {string}  The formatted string
 */
export const ucFirst = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

/**
 * Replace line breaks by HTML br tags
 *
 * @param {string} string The string to format
 * @return {string}  The formatted string
 */
export const nl2br = (string) => string.replaceAll(/(?:\r\n|\r|\n)/g, '<br />');

/**
 * Format a phone number to a E.164 standardized phone number
 *
 * @param {string} tel  The phone number to format
 * @return {string}  The formatted phone number
 */
export const formatTel = (tel) => tel.replaceAll(/[\s+()–-]/gm, '').replace(/^/, '+');

/**
 * Format an url for the display, removing the protocol & the eventual www
 *
 * @param {string} url  The url to format
 * @return {string}  The formatted url
 */
export const formatUrl = (url) => url.replace(/https?:\/\/w{0,3}\.?/, '').replace(/\/$/, '');

/**
 * Format a text for the display, adding unsecable spaces around specials characters like (:,«,»)
 *
 * @param {string} text  The text to format
 * @return {string}  The formatted text
 */
export const formatText = (text) => {
  return text ? text.replaceAll(' :', '&nbsp;:').replaceAll('« ', '«&nbsp;').replaceAll(' »', '&nbsp;»') : '';
};

/**
 * Format a html text for the display, replacing HTML blockquotes tags by div.block tags, and removing orphan p tags
 *
 * @param {string} text  The text to format
 * @return {string}  The formatted text
 */
export const formatHtmlText = (text) => {
  if (text) {
    text = formatText(text);
    return text
      .replaceAll('<blockquote>', '<div class="block"><div>')
      .replaceAll('</blockquote>', '</div></div>')
      .replaceAll('<p>&nbsp;</p>', '');
  } else {
    return '';
  }
};

/**
 * Format a title for the display, replacing numbers between parenthesis by HTML span.headings-number.number-$1 tags
 *
 * @param {string} title  The title to format
 * @return {string}  The formatted title
 */
export const formatHtmlTitle = (title) => {
  if (title) {
    title = formatText(title);
    return title.replace(/\(([0-9])\)\s?/, '<span class="headings-numbers number-$1"></span>');
  } else {
    return '';
  }
};

/**
 * Format an address for the display, eventually adding html microformats
 *
 * @param {Object} address  The address to format
 * @param {string} address.name  The name of the organization
 * @param {string} address.street  The address street
 * @param {string} address.number  The street number
 * @param {string} address.city  The address city
 * @param {string} address.zip  The address zip code
 * @param {string} address.country  The address country
 * @param {boolean} addMF  Whether to add microformats
 * @return {string}  The formatted address
 */
export const formatAddress = (address, addMF = true) => {
  let formatted = '';
  if (address.name && address.name.length > 0) {
    formatted += `
      <strong class="${addMF ? 'p-additional-name' : ''}">
        ${address.name}
      </strong><br />`;
  }
  if ((address.street && address.street.length > 0) || (address.number && address.number.length > 0)) {
    formatted += `
      <span class="${addMF ? 'p-street-address' : ''}">
        ${address.number.length > 0 && `${address.number}, `}
        ${address.street.length > 0 && `${address.street}`}
      </span><br />`;
  }
  if (
    (address.city && address.city.length > 0) ||
    (address.zip && address.zip.length > 0) ||
    (address.country && address.country.length > 0)
  ) {
    if (address.country.length > 0) {
      formatted += address.country.slice(0, 1);
    }
    if (address.country.length > 0 && (address.zip.length > 0 || address.city.length > 0)) {
      formatted += '-';
    }
    if (address.zip.length > 0) {
      formatted += `<span class="${addMF ? 'p-postal-code' : ''}">${address.zip}</span>`;
    }
    if (address.city.length > 0) {
      formatted += ` <span class="${addMF ? 'p-locality' : ''}">${address.city}</span>`;
    }
    formatted += '<br />';
  }
  if (address.country && address.country.length > 0) {
    formatted += `
      <span class="${addMF ? 'p-country-name' : ''}">
        ${address.country}
      </span>`;
  }
  return formatted;
};
