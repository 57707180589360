import PropTypes from 'prop-types';

const IconPin = ({ className, fill }) => (
  <div className={`svg ico-pin ${className}`}>
    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0C3.13 0 0 3.13 0 7C0 8.74 0.5 10.37 1.41 11.84C2.36 13.38 3.61 14.7 4.57 16.24C5.04 16.99 5.38 17.69 5.74 18.5C6 19.05 6.21 20 7 20C7.79 20 8 19.05 8.25 18.5C8.62 17.69 8.95 16.99 9.42 16.24C10.38 14.71 11.63 13.39 12.58 11.84C13.5 10.37 14 8.74 14 7C14 3.13 10.87 0 7 0ZM7 9.75C5.62 9.75 4.5 8.63 4.5 7.25C4.5 5.87 5.62 4.75 7 4.75C8.38 4.75 9.5 5.87 9.5 7.25C9.5 8.63 8.38 9.75 7 9.75Z"
        fill={fill}
      />
    </svg>
  </div>
);

IconPin.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

IconPin.defaultProps = {
  className: '',
  fill: '#0D1C55',
};

export default IconPin;
