import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const newsSlice = createSlice({
  name: 'news',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    currentNews: {},
  },
  reducers: {
    /**
     * Save the news list
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The news list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload;
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current news
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The current news
     */
    saveCurrentNews: (state, action) => {
      state.currentNews = action.payload;
    },
  },
});

export const { saveList, saveError, saveCurrentNews } = newsSlice.actions;

/**
 * Return the news list filtered by a given language
 *
 * @param {string} language  The news language
 * @return {Object}
 */
export const selectList = createSelector(
  (state) => state.news,
  (_, language) => language,
  (news, language) => news.list.filter((news) => news.lang === language)
);

/**
 * Return a slice the news list filtered by a given language
 *
 * @param {string} language  The news language
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @return {Object}
 */
export const selectSlice = createSelector(
  (state) => state.news,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (news, language, start, length) =>
    news.list.filter((news) => news.lang === language).slice(start || 0, length ? (start || 0) + length : undefined)
);

export default newsSlice.reducer;
