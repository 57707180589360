import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/** Hooks */
import useNavigateWithLoader from '../../hooks/useNavigateWithLoader';

/** Components */
import Template from '../Template';
import Container from '../Container';
import Button from '../Button';
import PageHeader from '../layouts/PageHeader';

const Page404 = (props) => {
  const { navigate } = useNavigateWithLoader();
  const { t: __ } = useTranslation();
  const { removeLoader } = useNavigateWithLoader();

  const template = { name: 'Page404', id: 'page-404' };

  /** Remove the loader */
  useEffect(() => {
    removeLoader();
  }, [removeLoader]);

  return (
    <Template template={template}>
      <PageHeader
        template={template}
        content={{
          title: __('templates.404.Page not found'),
          text: __('templates.404.The page you are looking for does not exist'),
        }}
      />
      <Container className="text-center">
        <div className="content">
          <h2>{__('templates.404.Page not found')}</h2>
          <p>{__('templates.404.The page you are looking for does not exist')}</p>
          <Button className="button" layout="square-outline" onPress={() => navigate('/')}>
            {__('templates.404.Back to the front page')}
          </Button>
        </div>
      </Container>
    </Template>
  );
};

export default Page404;
