import PropTypes from 'prop-types';
import { useRef, forwardRef, createContext, useContext } from 'react';
import { useRadioGroupState } from '@react-stately/radio';
import { useRadioGroup, useRadio } from '@react-aria/radio';
import { useFocusRing } from '@react-aria/focus';

const RadioGroupContext = createContext(null);

const RadioGroup = forwardRef((props, groupRef) => {
  const { radios, disabled, label } = props;
  const groupState = useRadioGroupState(props);
  const { groupProps, labelProps } = useRadioGroup(props, groupState);

  return (
    <div ref={groupRef} {...groupProps} className="radio-group">
      {label && <span {...labelProps}>{label}</span>}
      <RadioGroupContext.Provider value={groupState}>
        {radios.map((value, index) => (
          <Radio key={index} value={value} isDisabled={disabled.includes(value)}>
            {value}
          </Radio>
        ))}
      </RadioGroupContext.Provider>
    </div>
  );
});

const Radio = (props) => {
  const { value, children } = props;

  const inputRef = useRef();
  const inputState = useContext(RadioGroupContext);
  const { inputProps } = useRadio(props, inputState, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = inputState.selectedValue === value;
  const isDisabled = inputState.isDisabled || props.isDisabled;

  return (
    <label className={`radio-label ${isDisabled ? 'is-disabled' : ''}`}>
      <span className="visually-hidden">
        <input ref={inputRef} {...inputProps} {...focusProps} />
      </span>
      <svg className={`radio ${isSelected ? 'is-selected' : ''}`} width={24} height={24} aria-hidden="true">
        <rect x={5} y={5} width={14} height={14} strokeWidth={1} />
        {isSelected && (
          <path
            transform="translate(7 7)"
            d={`M3.788 9A.999.999 0 0 1 3 8.615l-2.288-3a1 1 0 1 1
              1.576-1.23l1.5 1.991 3.924-4.991a1 1 0 1 1 1.576 1.23l-4.712
              6A.999.999 0 0 1 3.788 9z`}
          />
        )}
        {isFocusVisible && <rect className="focus" x={3} y={3} width={18} height={18} strokeWidth={1} />}
      </svg>
      {children}
    </label>
  );
};

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
  radios: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

RadioGroup.defaultProps = {
  disabled: [],
};

export default RadioGroup;
