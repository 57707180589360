import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import useNavigateWithLoader from '../../hooks/useNavigateWithLoader';

/** Components */
import Container from '../Container';
import MainMenu from '../menus/MainMenu';
import SocialMenu from '../menus/SocialMenu';
import Button from '../Button';
import Link from '../Link';
import LogoUnipop from '../../images/LogoUnipop';

const Footer = (props) => {
  const { t: __, i18n } = useTranslation();

  /** Navigation */
  const { navigate } = useNavigateWithLoader();
  const pageContact = useSelector((state) => selectPage(state, 'contact', i18n.language));
  const pageCookiePrivacy = useSelector((state) => selectPage(state, 'privacy-notice', i18n.language));
  const pageLegalNotice = useSelector((state) => selectPage(state, 'legal-notice', i18n.language));

  const theme = useSelector((state) => state.theme.mode);

  const isFrontPage = useSelector((state) => state.pages.currentPage.template.id === 'front-page');
  const isNewsPage = useSelector((state) => state.pages.currentPage.template.id === 'news');
  const isFeedsPage = useSelector((state) => state.pages.currentPage.template.id === 'feeds');
  const layout = isFrontPage || isNewsPage || isFeedsPage ? theme : 'dark';

  return (
    <footer className={`footer l-${layout}`}>
      <Container layout="large">
        <div className="footer-content">
          <div className="footer-section">
            <h2 className="footer-logo">
              <span className="visually-hidden">Université Populaire Luxembourg</span>
              <LogoUnipop />
            </h2>
          </div>
          <div className="footer-section l-nav">
            <nav className="nav footer-nav footer-nav-primary">
              <MainMenu onAction={navigate} />
            </nav>
          </div>
          <div className="footer-section l-contact">
            <div className="menu contact-menu">
              <p>
                <Link layout="button-square-outline" url={pageContact?.link} title={__('contacts.Contact')} />
              </p>
            </div>
          </div>
          <div className="footer-section l-soc">
            <SocialMenu className="soc-menu" />
          </div>
          <div className="footer-section l-legal">
            <Button
              className="top-button"
              icon="triangle-up"
              iconLayout="round"
              ariaLabel={__('contacts.Back to page top')}
              onPress={() => window.scrollTo({ top: 0 })}
            />
            <ul className="menu legal-menu">
              <li>
                <Link url={pageCookiePrivacy?.link} title={__('contacts.Cookie privacy')} />
              </li>
              <li>
                <Link url={pageLegalNotice?.link} title={__('contacts.Legal notice')} />
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
