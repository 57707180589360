import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isContentLoaded: false,
  },
  reducers: {
    /**
     * Save the `isContentLoaded` state
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {boolean} action.payload  Whether the content is loaded
     */
    saveIsContentLoaded: (state, action) => {
      state.isContentLoaded = action.payload;
    },
  },
});

export const { saveIsContentLoaded } = appSlice.actions;

export default appSlice.reducer;
