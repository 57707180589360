import PropTypes from 'prop-types';

const Container = ({ className, children, layout }) => (
  <div className={`container ${className} ${layout ? `l-${layout}` : ''}`}>{children}</div>
);

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  layout: PropTypes.oneOf(['large', 'narrow']),
};

Container.defaultProps = {
  className: '',
};

export default Container;
