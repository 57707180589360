import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTextField } from '@react-aria/textfield';

const FieldText = (props) => {
  const { label, inputClassName, description, errorMessage, labelProps: labelPropsFromProps, isRequired } = props;
  const fieldRef = useRef();
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, fieldRef);

  const inputClassNames = `form-field-input ${inputClassName || ''}`.trim();

  const mergedLabelProps = {
    ...labelProps,
    ...labelPropsFromProps,
    style: {
      ...labelProps.style,
      ...labelPropsFromProps?.style,
    },
  };

  return (
    <div className="form-field form-field-text">
      {isRequired ? (
        <label {...mergedLabelProps}>
          {label} <sup className="form-field-required">*</sup>
        </label>
      ) : (
        <label {...mergedLabelProps}>{label}</label>
      )}
      <div className="form-field-control">
        <input {...inputProps} className={inputClassNames} ref={fieldRef} />
      </div>
      {description && (
        <div className="form-field-description" {...descriptionProps}>
          {description}
        </div>
      )}
      {errorMessage && (
        <div className="form-field-error" {...errorMessageProps}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

FieldText.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  inputClassName: PropTypes.string,
  labelProps: PropTypes.object,
  isRequired: PropTypes.bool,
};

FieldText.defaultProps = {
  type: 'text',
  inputClassName: '',
  labelProps: {},
  isRequired: false,
};

export default FieldText;
