const PictoUnipop = () => (
  <div className="svg picto-unipop">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 440 440" xmlSpace="preserve">
      <path
        id="p"
        fill="#99D5D3"
        d="M342.71,129.79v86.4c0,61.71-31.56,94.02-85.9,94.02h-0.7c-58.2,0-88.7-36.88-88.7-97.53v-42.93H97.29v-39.96 H342.71z"
      />
      <path
        id="u"
        fill="#00164A"
        d="M206.54,225.42v-55.64h34.31V225c0,12.54,6.48,17.88,15.57,17.88c9.23,0,15.57-4.9,15.57-17.15v-55.96h34.31
          v54.93c0,34.46-20.04,48.01-50.17,48.01C226.15,272.71,206.54,258.87,206.54,225.42z"
      />
    </svg>
  </div>
);
export default PictoUnipop;
