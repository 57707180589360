import PropTypes from 'prop-types';

const IconContactbot = ({ className, bgColor, contentColor }) => (
  <div className={`svg ico-contactbot ${className}`}>
    <svg width="54" height="83" viewBox="0 0 54 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10C0 4.47716 4.47715 0 10 0H44C49.5228 0 54 4.47715 54 10V61.377V82.5882L46.0143 73.0722C44.1143 70.8081 41.3099 69.5005 38.3542 69.5005H10C4.47715 69.5005 0 65.0233 0 59.5005V10Z"
        fill={bgColor}
      />
      <path
        d="M44.8473 45.5294C41.7093 51.9278 34.8975 56.3607 26.9998 56.3607C19.1021 56.3607 12.2903 51.9278 9.15234 45.5294"
        stroke={contentColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="17" cy="23" r="3" fill={contentColor} />
      <circle cx="37" cy="23" r="3" fill={contentColor} />
    </svg>
  </div>
);

IconContactbot.propTypes = {
  className: PropTypes.string,
  bgColor: PropTypes.string,
  contentColor: PropTypes.string,
};

IconContactbot.defaultProps = {
  className: '',
  bgColor: '#61B57B',
  contentColor: '#0D1C55',
};

export default IconContactbot;
