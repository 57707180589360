import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const feedsSlice = createSlice({
  name: 'feeds',
  initialState: {
    isLoading: false,
    posts: [],
    error: null,
    currentFeed: {},
  },
  reducers: {
    /**
     * Begins fetching the feeds
     * @param {Object} state  The redux state
     */
    fetchFeedsBegin: (state) => {
      state.isLoading = true;
    },

    /**
     * Save the feeds posts
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The feeds list
     */
    fetchFeedsSuccess: (state, action) => {
      state.isLoading = false;
      state.posts = action.payload;
    },

    /**
     * Save the loading error
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    fetchFeedsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.posts = [];
    },
  },
});

export const { fetchFeedsBegin, fetchFeedsSuccess, fetchFeedsFailure } = feedsSlice.actions;

/**
 * Return the posts filtered by a given language
 *
 * @param {string} language  The post language
 * @return {Object}
 */
export const selectList = createSelector(
  (state) => state.feeds,
  (_, language) => language,
  (feeds, language) => feeds.posts.filter((post) => post.lang === language || !post.lang)
);

/**
 * Return a slice the posts filtered by a given language
 *
 * @param {string} language  The post language
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @return {Object}
 */
export const selectSlice = createSelector(
  (state) => state.feeds,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (feeds, language, start, length) =>
    feeds.posts
      .filter((post) => post.lang === language || !post.lang)
      .slice(start || 0, length ? (start || 0) + length : undefined)
);

export default feedsSlice.reducer;
