/** Redux */
import { useSelector } from 'react-redux';

/** Components */
import FormSearch from './FormSearch';
import TrainingsMenu from '../../menus/TrainingsMenu';
import ThemeSwitcher from '../../menus/ThemeSwitcher';

const NavQuicknav = () => {
  const currentPage = useSelector((state) => state.pages.currentPage);
  const isFrontPage = currentPage.template.id === 'front-page';

  return (
    <nav className="nav header-nav header-nav-quicknav">
      <ul className="menu">
        {/*{!isFrontPage && (
          <li className={`menu-title ${currentPage.template.id}`}>
            <span>{currentPage.title}</span>
          </li>
        )}*/}
        <li className="menu-trainings">
          <TrainingsMenu />
        </li>
        <li className="menu-search">
          <FormSearch />
        </li>
        {isFrontPage && (
          <li className="menu-switch">
            <ThemeSwitcher />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavQuicknav;
