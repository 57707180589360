import { useSelector } from 'react-redux';

/** Hooks */
import { useIsScrolled } from '../../hooks/useIsScrolled';

/** Components */
import Container from '../Container';
import Logo from './header/Logo';
import NavQuicknav from './header/NavQuicknav';
// import NavLanguages from './header/NavLanguages';
import NavPrimary from './header/NavPrimary';

const Header = () => {
  const theme = useSelector((state) => state.theme.mode);

  const isScrolled = useIsScrolled();
  const isFrontPage = useSelector((state) => state.pages.currentPage.template.id === 'front-page');
  const isNewsPage = useSelector((state) => state.pages.currentPage.template.id === 'news');
  const isFeedsPage = useSelector((state) => state.pages.currentPage.template.id === 'feeds');
  const layout = isFrontPage || isNewsPage || isFeedsPage ? theme : 'light';

  return (
    <header
      id="header"
      className={`header l-${layout} ${isFrontPage ? 'is-front-page' : ''} ${isScrolled ? 'is-scrolled' : ''}`}
      role="banner"
    >
      <Container layout="large">
        <div className="header-content">
          <Logo />
          <NavQuicknav />
          {/* <NavLanguages /> */}
          <NavPrimary />
        </div>
      </Container>
    </header>
  );
};

export default Header;
