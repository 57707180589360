import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Item } from '@react-stately/collections';

/** Components */
import Menu from '../Menu';
import Icon from '../Icon';

const SocialMenu = ({ className }) => {
  const { t: __ } = useTranslation();

  const social = useSelector((state) => state.contacts.social);

  return (
    <Menu className={className} onAction={window.open} aria-label={__('menu.Social networks')}>
      {social.facebook.length > 0 && (
        <Item key={social.facebook} textValue="Facebook">
          <Icon icon="f" layout="round" ariaLabel="Facebook" />
        </Item>
      )}
      {social.twitter.length > 0 && (
        <Item key={social.twitter} textValue="Twitter">
          <Icon icon="t" layout="round" ariaLabel="Twitter" />
        </Item>
      )}
      {social.instagram.length > 0 && (
        <Item key={social.instagram} textValue="Instagram">
          <Icon icon="i" layout="round" ariaLabel="Instagram" />
        </Item>
      )}
      {social.youtube.length > 0 && (
        <Item key={social.youtube} textValue="YouTube">
          <Icon icon="y" layout="round" ariaLabel="YouTube" />
        </Item>
      )}
      {social.linkedin.length > 0 && (
        <Item key={social.linkedin} textValue="LinkedIn">
          <Icon icon="l" layout="round" ariaLabel="LinkedIn" />
        </Item>
      )}
    </Menu>
  );
};

SocialMenu.propTypes = {
  className: PropTypes.string,
};

SocialMenu.defaultProps = {
  className: '',
};

export default SocialMenu;
