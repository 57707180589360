import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';

const ContactBotElement = ({ title, subtitle, icon: Icon, handle, linkUrl, target, titleColor, subtitleColor }) => {
  const content = (
    <>
      <div className="contactbot-list-left-content">
        <div className="contactbot-list-title" style={{ color: titleColor }}>
          {title}
        </div>
        <div className="contactbot-list-subtitle" style={{ color: subtitleColor }}>
          {subtitle}
        </div>
      </div>
      <div className="contactbot-list-right-content">{Icon && <Icon />}</div>
    </>
  );

  return linkUrl ? (
    <li>
      <Link url={linkUrl} target={target}>
        {content}
      </Link>
    </li>
  ) : (
    <li role="presentation" onClick={handle}>
      {content}
    </li>
  );
};

ContactBotElement.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.elementType,
  handle: PropTypes.func,
  linkUrl: PropTypes.string,
  target: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
};

ContactBotElement.defaultProps = {
  subtitle: '',
  icon: null,
  handle: () => {},
  linkUrl: '',
  target: '',
  titleColor: '#0D1C55',
  subtitleColor: '#A2A2A2',
};

export default ContactBotElement;
